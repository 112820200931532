export const OPTION_TYPE_NUMBER = "NUMBER";
export const OPTION_TYPE_STRING = "STRING";
export const OPTION_TYPE_WYSIWYG = "WYSIWYG";
export const OPTION_TYPE_CHECKBOX = "CHECKBOX";
export const OPTION_TYPE_OBJECT = "OBJECT";
export const OPTION_TYPE_ARRAY = "ARRAY";

export const OPTION_DEFAULT_TAXES_GROUP = "DEFAULT_TAXES_GROUP";
export const OPTIONS_DEFAULT_ESTIMATION_ITEM_HOURLY_RATE =
  "DEFAULT_ESTIMATION_ITEM_HOURLY_RATE";
