<template>
  <div class="container-fluid">
    <div class="page-title">
      <i class="fa-light fa-building-memo"></i>
      <h1>{{ $t("COMMON.ORGANIZATIONS") }}</h1>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("ORGANIZATIONS.ORGANIZATIONS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openOrganizationCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_ORGANIZATIONS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("ORGANIZATIONS.ADD_ORGANIZATION")
              }}</span>
            </base-button>
            <!--<notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_ORGANIZATIONS)"
              :objectType="'organizations'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />-->
          </div>
        </div>
      </div>

      <organization-list-table
        @onViewOrganization="openOrganizationViewModal"
        @onEditOrganization="openOrganizationEditModal"
        @onDeleteOrganization="deleteOrganization"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewOrganizationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewOrganizationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'ORGANIZATION'"
          @onCloseModal="closeOrganizationModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ORGANIZATIONS.VIEW_ORGANIZATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openOrganization"
                :objectType="'organizations'"
                :objectId="openOrganization.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openOrganizationEditModal(openOrganization)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  @click="deleteOrganization(openOrganization)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeOrganizationModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-organization-component
              v-if="openOrganization"
              :organizationId="openOrganization.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditOrganizationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditOrganizationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'ORGANIZATION'"
          @onCloseModal="closeOrganizationModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ORGANIZATIONS.EDIT_ORGANIZATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button
                class="close"
                @click="openOrganizationViewModal(openOrganization)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-organization-component
              v-if="openOrganization"
              :organizationId="openOrganization.id"
              @onViewOrganization="openOrganizationViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddOrganizationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddOrganizationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'ORGANIZATION'"
          @onCloseModal="closeOrganizationModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ORGANIZATIONS.ADD_ORGANIZATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeOrganizationModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-organization-component
              @onViewOrganization="openOrganizationViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import OrganizationListTable from "./partials/OrganizationListTable.vue";
import EditOrganizationComponent from "./components/EditOrganizationComponent.vue";
import AddOrganizationComponent from "./components/AddOrganizationComponent.vue";
import ViewOrganizationComponent from "./components/ViewOrganizationComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    OrganizationListTable,
    NotificationSubscription,
    EditOrganizationComponent,
    AddOrganizationComponent,
    ViewOrganizationComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const organizationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewOrganizationModalOpened = false;
    let isEditOrganizationModalOpened = false;
    let isAddOrganizationModalOpened = false;
    let openOrganization = null;
    if (organizationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewOrganizationModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditOrganizationModalOpened = true;
      }
      openOrganization = { id: organizationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddOrganizationModalOpened = true;
    }
    return {
      isViewOrganizationModalOpened: isViewOrganizationModalOpened,
      isEditOrganizationModalOpened: isEditOrganizationModalOpened,
      isAddOrganizationModalOpened: isAddOrganizationModalOpened,
      openOrganization: openOrganization,
      renderKey: 1,
    };
  },

  methods: {
    openOrganizationCreateModal() {
      this.closeOrganizationModal();
      this.isAddOrganizationModalOpened = true;

      this.$router.replace({
        name: "List Organizations",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },
    openOrganizationViewModal(organization, reRender = false) {
      this.closeOrganizationModal();
      this.openOrganization = organization;
      this.isViewOrganizationModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Organizations",
        query: { id: this.openOrganization.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    openOrganizationEditModal(organization) {
      this.closeOrganizationModal();
      this.openOrganization = organization;
      this.isEditOrganizationModalOpened = true;

      this.$router.replace({
        name: "List Organizations",
        query: { id: this.openOrganization.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    closeOrganizationModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddOrganizationModalOpened = false;
      this.isViewOrganizationModalOpened = false;
      this.isEditOrganizationModalOpened = false;
      this.openOrganization = null;

      if (
        this.$route.name !== "List Organizations" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Organizations",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async deleteOrganization(organization) {
      const confirmation = await swal.fire({
        title: this.$t("ORGANIZATIONS.DELETE_THIS_ORGANIZATION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("organizations/destroy", organization.id);
          this.renderKey++;
          this.closeOrganizationModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("ORGANIZATIONS.ORGANIZATION_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
