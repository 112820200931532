<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ organization.name }} - {{ $t("COMMON.SUBSCRIPTIONS") }}
    </h3>
    <subscription-list-table
      :filterOrganization="organization.id"
      @onViewSubscription="openViewSubscription"
      @onEditSubscription="openEditSubscription"
    />
  </div>
</template>

<script>
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import SubscriptionListTable from "@/views/Pages/ResellerModule/SubscriptionManagement/partials/SubscriptionListTable.vue";

export default {
  name: "organization-view-subscriptions",

  components: { SubscriptionListTable },

  props: ["organization"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    openEditSubscription(subscription) {
      this.$router.push({
        name: "List Subscriptions",
        query: { id: subscription.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    openViewSubscription(subscription) {
      this.$router.push({
        name: "List Subscriptions",
        query: { id: subscription.id, action: QUERY_ACTIONS_VIEW },
      });
    },
  },

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
