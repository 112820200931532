<template>
  <div class="image-upload-wrapper">
    <div class="image-upload">
      <div class="image-upload-picture">
        <div v-if="image" class="profile-image">
          <img :src="`${image}`" class="profile-image argon-image" />
        </div>
        <div v-else class="profile-image default">
          <img src="/img/add-image.svg" class="argon-image" />
        </div>
      </div>
      <div class="image-upload-buttons">
        <span v-if="loading" class="loading">
          <i class="fal fa-spinner fa-spin"></i>
        </span>
        <base-button
          v-if="image"
          type="button"
          class="btn btn-sm remove"
          @click="removeImage"
        >
          <span>
            <i class="fal fa-trash-alt"></i>
          </span>
        </base-button>
        <base-button type="button" class="btn btn-sm choose">
          <label :for="`imageInput-${componentId}`"></label>
          <input
            :id="`imageInput-${componentId}`"
            :ref="`imageInput-${componentId}`"
            accept="image/*"
            type="file"
            @input="onFileChange"
          />
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "image-selector",

  props: {
    defaultImage: {
      type: String,
      default: null,
      description: "Default image to diplay, should be model value",
    },
    label: {
      type: String,
      default: null,
      description: "Label of the field",
    },
    ressource_name: {
      type: String,
      default: null,
      description:
        "Ressource name to be uploaded with, must be allowed in the api",
    },
    ressouce_id: {
      type: String,
      default: null,
      description:
        "Ressource id to be uploaded with, must be allowed in the api",
    },
    field: {
      type: String,
      default: null,
      description:
        "Ressource field to be uploaded with, must be allowed in the api",
    },
  },

  setup() {},

  data() {
    return {
      image: this.defaultImage,
      componentId: null,
      loading: false,
    };
  },

  mounted() {
    this.componentId = this._uid;
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadImage(files[0]);
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    async uploadImage(file) {
      this.loading = true;
      if (file) {
        try {
          await this.$store.dispatch("files/upload", {
            image: file,
            ressource_name: this.ressource_name,
            ressource_id: this.ressource_id,
            field: this.field,
          });
          const image_url = await this.$store.getters["files/url"];
          this.$emit("imageChanged", image_url);
          this.loading = false;
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
        }
      }
    },

    removeImage() {
      this.$refs[`imageInput-${this.componentId}`].value = null;
      this.image = null;
      this.$emit("imageChanged", null);
    },
  },

  watch: {
    defaultImage(defaultImage) {
      this.image = defaultImage;
    },
  },
};
</script>

<style lang="scss">
.image-upload-wrapper {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 10px 0 1.5rem 0;

  .image-upload {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto 0 0;
    padding: 0;

    &:hover {
      button.remove {
        visibility: visible;
        opacity: 1;
      }
    }

    &-picture {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      z-index: 9;

      .profile-image {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &.default {
          img {
            width: 43px;
            height: auto;
            border-radius: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
          padding: 0;
          border-radius: 50%;
          object-fit: contain;
          border: none;
        }
      }
    }

    &-buttons {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      .loading {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        i {
          font-size: 24px;
        }
      }

      button {
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 50%;
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
        position: absolute;
        top: 0;
        left: 0;

        &:hover {
          box-shadow: none;
          transform: none;
        }

        &.remove {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          opacity: 0;
          visibility: hidden;
          background-color: rgb(255, 0, 0, 0.6);
          z-index: 2;
          margin: 0;
          i {
            color: #fff;
            font-size: 30px;
          }
        }

        .choose {
          z-index: 1;
        }

        label {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          background-color: transparent;
          margin: 0;
          cursor: pointer;
        }

        input {
          display: none;
        }
      }
    }
  }
}
</style>
