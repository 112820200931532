<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ organization.name }} - {{ $t("COMMON.OPTIONS") }}</h3>
    <option-list-table :filter-organization="organization.id" />
  </div>
</template>

<script>
import OptionListTable from "../../OptionManagement/partials/OptionListTable.vue";

export default {
  name: "organization-view-options",

  components: {
    OptionListTable,
  },

  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
